import {StateType} from "./appState"


export enum ActionKind {
  LOAD_STATE,
  SET_TOKEN,
  CLEAR_TOKEN
}

export interface Action {
  type: ActionKind
  payload?: any
}

function parseJwt(token: string): any {
  try {
    const payloadBase64 = token.split(".")[1]
    const decodedPayload = atob(payloadBase64)
    return JSON.parse(decodedPayload)
  } catch (error) {
    console.error("Failed to parse JWT:", error)
    return null;
  }
}

export function reducer(state: StateType, action: Action) {
  const {type, payload} = action;
  console.log(type, payload);
  switch (type) {
    case ActionKind.CLEAR_TOKEN:
      return {
        ...state,
        token: undefined,
        authType: undefined,
      };
    case ActionKind.SET_TOKEN:
      const token = payload.token
      const parsedPayload = parseJwt(token)
      return {
        ...state,
        token,
        authType: parsedPayload?.auth_type,
      };
    default:
      return {
        ...state,
        ...payload
      }
  }
}
