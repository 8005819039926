import List from "@mui/material/List";
import React from "react";
import {Divider, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import BarChart from "@mui/icons-material/BarChart";
import PhoneForwarded from "@mui/icons-material/PhoneForwarded";
import PersonIcon from '@mui/icons-material/Person';
import SmsIcon from '@mui/icons-material/Sms';
import AddchartIcon from '@mui/icons-material/Addchart';
import KeyIcon from '@mui/icons-material/Key';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Logout from "@mui/icons-material/Logout";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppContext} from "./AppContextProvider";
import logout from "../handlers/auth/logout";


export default function DrawerContent() {

  const {dispatch, state} = useAppContext();

  const navigate = useNavigate();

  const authType = state.authType

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    href?: string
  ) => {
    href && navigate(href);
  }

  const location = useLocation()

  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton
          selected={location.pathname.indexOf('info') > 0}
          onClick={(event) =>
            handleListItemClick(event, 0, 'info')}
        >
          <ListItemIcon><PersonIcon/></ListItemIcon>
          <ListItemText primary="Данные абонента" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={location.pathname.indexOf('sms') > 0}
          onClick={(event) =>
            handleListItemClick(event, 0, 'sms')}
        >
          <ListItemIcon><SmsIcon/></ListItemIcon>
          <ListItemText primary="Поиск сообщений" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={location.pathname.indexOf('transactions') > 0}
          onClick={(event) =>
            handleListItemClick(event, 0, 'transactions')}
        >
          <ListItemIcon><ReceiptLongIcon/></ListItemIcon>
          <ListItemText primary="Поиск транзакций" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          selected={location.pathname.indexOf('report') > 0}
          onClick={(event) =>
            handleListItemClick(event, 0, 'report')}
        >
          <ListItemIcon><BarChart/></ListItemIcon>
          <ListItemText primary="Отчёты" />
        </ListItemButton>
      </ListItem>
        <ListItem disablePadding>
            <ListItemButton
                selected={location.pathname.indexOf('box') > 0}
                onClick={(event) =>
                    handleListItemClick(event, 0, 'box')}
            >
                <ListItemIcon><PhoneForwarded/></ListItemIcon>
                <ListItemText primary="SIM-карты" />
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton
                selected={location.pathname.indexOf('campaigns') > 0}
                onClick={(event) =>
                    handleListItemClick(event, 0, 'campaigns')}
            >
                <ListItemIcon><AddchartIcon/></ListItemIcon>
                <ListItemText primary="Рекламные кампании" />
            </ListItemButton>
        </ListItem>
        { authType === "login" && (
            <ListItem disablePadding>
                <ListItemButton
                    selected={location.pathname.indexOf('change-password') > 0}
                    onClick={(event) =>
                        handleListItemClick(event, 0, 'change-password')}
                >
                    <ListItemIcon><KeyIcon/></ListItemIcon>
                    <ListItemText primary="Сменить пароль" />
                </ListItemButton>
            </ListItem>
        ) }
      <Divider/>
      <ListItem disablePadding>
        <ListItemButton
          onClick={(event) => {
            handleListItemClick(event, -1);
            logout(dispatch, navigate);
          }}
        >
          <ListItemIcon><Logout/></ListItemIcon>
          <ListItemText primary="Выход" />
        </ListItemButton>
      </ListItem>
    </List>
  );

}

