import Box from "@mui/material/Box"
import {useFormik} from "formik"
import * as yup from "yup"
import {Button, TextField, Typography, useTheme} from "@mui/material"
import {useState} from "react"
import {apiPost} from "../../api/common"
// @ts-ignore
import md5 from "md5"

const validationSchema = yup.object({
    login: yup.string().required("Введите логин"),
    password: yup.string().required("Введите пароль"),
})

export default function PasswordForm({
                                         onSuccess,
                                     }: {
    onSuccess: (token: string) => void
}) {
    const [formError, setFormError] = useState<string>()

    const theme = useTheme()

    const formik = useFormik({
        initialValues: {
            login: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log("submit login", values.login)
            setFormError(undefined)

            const hashedPassword = md5(values.password) // Хэшируем пароль
            apiPost("/auth/login", {
                login: values.login,
                password: hashedPassword,
            })
                .then((response: any) => {
                    if (response.state === "ok" && response.token) {
                        onSuccess(response.token)
                    } else {
                        setFormError("Неверный логин или пароль")
                    }
                })
                .catch((error) => {
                    console.log("got error", error)
                    if (error.message.trim() == "wrong password") {
                        setFormError("Неверный логин или пароль")
                    } else {
                        setFormError("Произошла ошибка")
                    }
                })
        },
    })

    return (
        <form onSubmit={formik.handleSubmit} style={{display: "flex", flexDirection: "column"}}>
            <Box height={theme.typography.htmlFontSize * 1.5} sx={{mb: 1}}>
                {formError && (
                    <Typography color={theme.palette.error.main}>{formError}</Typography>
                )}
            </Box>
            <TextField
                variant="filled"
                id="login"
                name="login"
                label="Логин"
                value={formik.values.login}
                onChange={formik.handleChange}
                error={formik.touched.login && Boolean(formik.errors.login)}
                helperText={formik.touched.login && formik.errors.login}
                sx={{mb: 2}}
            />
            <TextField
                variant="filled"
                id="password"
                name="password"
                label="Пароль"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                sx={{mb: 2}}
            />
            <Button type="submit" variant="outlined" fullWidth>
                Отправить
            </Button>
        </form>
    )
}
