import React, { useState } from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import { Button, TextField, Typography, Box, useTheme } from "@mui/material"
import { apiPost } from "../api/common"
// @ts-ignore
import md5 from "md5"
import { useSnackbar } from "notistack"
import {ActionKind} from "../state/appReducer";
import {useAppContext} from "./AppContextProvider";

export default function ChangePassword() {
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar()

    const [formError, setFormError] = useState<string | null>(null)
    const {dispatch} = useAppContext()

    const validationSchema = yup.object({
        oldPassword: yup.string().required("Введите текущий пароль"),
        newPassword: yup
            .string()
            .required("Введите новый пароль")
            .min(8, "Пароль должен быть не менее 8 символов"),
        confirmPassword: yup
            .string()
            .required("Подтвердите новый пароль")
            .oneOf([yup.ref("newPassword"), null], "Пароли не совпадают"),
    })

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setFormError(null)

            const payload = {
                old_password: md5(values.oldPassword),
                new_password: md5(values.newPassword),
            }

            apiPost("/auth/changePassword", payload)
                .then((response: any) => {
                    if (response && response.token) {
                        enqueueSnackbar("Пароль успешно изменён", { variant: "success" });


                        dispatch({ type: ActionKind.SET_TOKEN, payload: { token: response.token } });

                        formik.resetForm();
                    } else {
                        setFormError("Не удалось получить новый токен. Попробуйте снова.");
                    }
                })
                .catch((error) => {
                    console.error("Error changing password:", error);
                    setFormError("Не удалось изменить пароль. Попробуйте снова.");
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    })

    return (
        <Box
            sx={{
                maxWidth: 400,
                margin: "auto",
                mt: theme.spacing(4),
                p: theme.spacing(3),
                borderRadius: 1,
                boxShadow: theme.shadows[2],
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <Typography variant="h6" align="center" sx={{ mb: theme.spacing(2) }}>
                Смена пароля
            </Typography>
            {formError && (
                <Typography color="error" sx={{ mb: theme.spacing(2) }}>
                    {formError}
                </Typography>
            )}
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="oldPassword"
                    name="oldPassword"
                    label="Текущий пароль"
                    type="password"
                    value={formik.values.oldPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                    helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                    sx={{ mb: theme.spacing(2) }}
                />
                <TextField
                    fullWidth
                    id="newPassword"
                    name="newPassword"
                    label="Новый пароль"
                    type="password"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                    sx={{ mb: theme.spacing(2) }}
                />
                <TextField
                    fullWidth
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Подтвердите новый пароль"
                    type="password"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                        formik.touched.confirmPassword && formik.errors.confirmPassword
                    }
                    sx={{ mb: theme.spacing(2) }}
                />
                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={formik.isSubmitting}
                >
                    Изменить пароль
                </Button>
            </form>
        </Box>
    )
}
